import React, { useEffect, useState, useContext } from 'react'
import { useFormContext } from "react-hook-form"
import { Grid } from '@material-ui/core'
import ArchivedDataUploadApiUrls from 'src/apiUrls/ArchivedDataUploadApiUrls.js'
import Namespace from 'src/constants/locale/Namespace'
import ArchivedDataUploadKeys from '../../constants/locale/key/ArchivedDataUpload'
import makeValidationSchema from './MakeValidationSchema'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop'
import ArchivedDataUploadButtonComponent from './component/ArchivedDataUploadButtonComponent'
import CngSection from '../../components/cngcomponents/CngSection'
import { v4 as uuid } from 'uuid'

import {
  components,
  DataFlattener,
  useServices,
  useTranslation,
  constants
} from 'cng-web-lib'

const {
  form: { field: { 
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField 
    } 
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem
} = components

const { filter: { EQUAL } } = constants

const FILE_FOR_USER_PARTY_NAME = 'fileForUserPartyName'
const FILE_FOR_USER_BY_ADMIN = 'fileForUserByAdmin'
const FILE_FOR_USER_DETAILS = 'fileForUserDetails'

const DEFAULT_INITIAL_VALUES = Object.freeze({ 
  module: '',
  type: '',
  restoreBy: 'DATE_RANGE',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { securedSendRequest, fetchRecords } = useServices();
  const { translate } = useTranslation(Namespace.DATA_RESTORE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [subModuleDetailState, setSubModuleDetailState] = useState(false);
  const [subModuleState, setSubModuleState] = useState(uuid());
  const [restoreBySearchCriteriaState, setRestoreBySearchCriteriaState] = useState(false);
  const [showDateRangeState, setShowDateRangeState] = useState(false);

  const [configState, setConfigState] = useState(
    {
      labelModule: translatedTextsObject.module + ' *',
      labelType: translatedTextsObject.type + ' *',
      labelRestoreBy: translatedTextsObject.restoreBy + ' *',
      labelSearchCriteria: '',
      dropDownSubModuleType: '-',
      dropDownRestoreBy: '-',
      archivedDataUploadAPIUrl: ''
    }
  )

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [loadingState, setLoadingState] = new useState(false);
  const [isSuperParty, setSuperParty] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({})  
  const [user, setUser] = useState({})  

  useEffect(() => {
    let module = getValues('module');
    setValue('type', '')

    //Add on changes
    if (module == 'ACEHW') {
      configState.dropDownSubModuleType = 'DR_ACEHW_SUB_MODULE'
    }

    if (module == 'ACEOCN') {
      configState.dropDownSubModuleType = 'DR_ACEOCN_SUB_MODULE'
    }

    //refresh form details if module is not empty
    if (module != null && module != undefined && module != '') {
      setSubModuleState(uuid())
      setSubModuleDetailState(true);
    }
  }, [getValues('module')]);

  useEffect(() => {
    let module = getValues('module');
    let type = getValues('type');
    if (type != '' && type != undefined) {
      setShowDateRangeState(true)

      //Add on changes
      if (module == 'ACEHW') {
        configState.archivedDataUploadAPIUrl = ArchivedDataUploadApiUrls.ACE_HIGHWAY
      }

      if (module == 'ACEOCN') {
        configState.archivedDataUploadAPIUrl = ArchivedDataUploadApiUrls.ACE_OCEAN
      }
    }
  }, [getValues('type')]);

  function getUserId() {
    trigger();
    let username = getValues('userId');

    function onSuccess(response) {
      let data = response.data;

      if (data) {
        let error = data.errorMessages;
        if (error === null || error === undefined) {
          if (data.content && data.content.length > 0) {
            getPartyId(data.content[0].id);
          } else {
            showNotification('error', 'Invalid user ID.');
          }
        } else {
          showNotification('error', 'Invalid user ID.');
        }
      }
    }

    function onError(error) {
      showNotification('error', "We've encountered some issue.Please try again later.");
    }

    function onComplete() { }

    const formData = {
      baseFilterDTO: {
        filterType: 'AND',
        filterProperties: [{
          fieldName: 'loginId',
          operatorType: 'EQUAL',
          value1: username
        }],
        sortProperties: []
      }
    }

    const config = {}

    if (username) {
      securedSendRequest.execute('POST', ArchivedDataUploadApiUrls.GET_TPR_USER, formData, onSuccess, onError, onComplete, config, null);
    }
  }

  function getPartyId(userProfileId) {

    function onSuccess(response) {
      let data = response.data;

      if (data) {
        let error = data.errorMessages;
        if (error === null || error === undefined) {
          if (data.content && data.content.length > 0) {
            let tprUserDetails = data.content[0].tprUserDetail;

            if (tprUserDetails != null & tprUserDetails != undefined) {
              setValue('partyId', tprUserDetails.partyId);
              onRestore()
            }

          } else {
            showNotification('error', 'Invalid user ID.');
          }
        } else {
          showNotification('error', 'Invalid user ID.');
        }
      }
    }

    function onError(error) {
      showNotification('error', "We've encountered some issue.Please try again later.");
    }

    function onComplete() { }

    const formData = {
      baseFilterDTO: {
        filterType: 'AND',
        filterProperties: [{
          fieldName: 'userProfileId',
          operatorType: 'EQUAL',
          value1: userProfileId
        }],
        sortProperties: []
      }
    }

    const config = {}

    securedSendRequest.execute('POST', ArchivedDataUploadApiUrls.GET_TPR_USER_PREFERENCE, formData, onSuccess, onError, onComplete, config, null);
  }  

  function onRestore() {
    let submitData = {}

    submitData.module = getValues('module')
    submitData.type = getValues('type')
    submitData.restoreBy = getValues('restoreBy')
    submitData.searchCriteria = getValues('searchCriteria')
    submitData.dateFrom = getValues('dateFrom')
    submitData.dateTo = getValues('dateTo')
    submitData.dayToRetain = getValues('dayToRetain')
    submitData.partyId = getValues('partyId')

    function onSuccess(response) {

      if (response.status == 200) {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages != undefined && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else {
          showNotification('success', translatedTextsObject.successMessage)
        }
      } else {
        showNotification('error', translatedTextsObject.errorMessage)
      }
    }

    function onError(error) {
      console.error('Error', error)
      showNotification('error', translatedTextsObject.errorMessage)
    }

    function onComplete(response) {
      setLoadingState(false)
    }
    const config = {}

    //validate form
    const result = trigger()
    result.then(function (result) {
      if (result == true) {
        setLoadingState(true)
        //submit
        securedSendRequest.execute('POST', configState.archivedDataUploadAPIUrl, submitData, onSuccess, onError, onComplete, config, null);
      }
    })
  }

  function onSubmit() {
    getUserId()
  }

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.TITLE
    )

    let module = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.MODULE
    )

    let type = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.TYPE
    )

    let restoreBy = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.RESTORE_BY
    )

    let dateFrom = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.DATE_FROM
    )
    
    let dateTo = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.DATE_TO
    )
    
    let bondNumber = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.BOND_NUMBER
    )    

    let tripNumber = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.TRIP_NUMBER
    )  

    let shipmentControlNumber = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.SHIPMENT_CONTROL_NUMBER
    )  

    let dayToRetain = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.DAY_TO_RETAIN
    )  

    let userId = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.USER_ID
    )

    let errorMessage = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.ERROR_MESSAGE
    )

    let successMessage = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.SUCCESS_MESSAGE
    )
   
    return {
      title,
      module,
      type,
      restoreBy,
      userId,
      dateFrom,
      dateTo,
      bondNumber,
      tripNumber,
      shipmentControlNumber,
      dayToRetain,
      errorMessage,
      successMessage,
    }
  }

  return loadingState ? (
    <CngBackdrop loading />
  ) : (
    <CngSection title={translatedTextsObject.title}>
      <Grid container justify='flex-end' spacing={2}>
        <CngGridItem xs={12}>
          <Grid container spacing={2}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userId}>
              <CngCodeMasterAutocompleteField
                name='module'
                label={configState.labelModule}
                disabled={disabled}
                codeType='DR_MODULE'
              />
            </CngGridItem>
            {subModuleDetailState &&
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                <CngCodeMasterAutocompleteField
                  key={subModuleState}
                  name='type'
                  label={configState.labelType}
                  disabled={disabled}
                  codeType={configState.dropDownSubModuleType}
                />
              </CngGridItem>
            }
            {showDateRangeState &&
              <>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngDateField
                    name='dateFrom'
                    label={translatedTextsObject.dateFrom}
                    disabled={disabled}
                    isRequired
                    size='small' />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngDateField
                    name='dateTo'
                    label={translatedTextsObject.dateTo}
                    disabled={disabled}
                    isRequired
                    size='small' />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
                  <CngTextField
                    name='userId'
                    inputProps={{ maxLength: 35 }}
                    label={translatedTextsObject.userId}
                    disabled={disabled}
                    isRequired
                    size='small'
                  />  
                </CngGridItem>  
              </>       
            }            
          </Grid>
        </CngGridItem>
        <CngGridItem xs='auto'>
          <ArchivedDataUploadButtonComponent onSubmit={onSubmit} />
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties; 