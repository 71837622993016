import React from "react";
import Namespace from 'src/constants/locale/Namespace'
import ArchivedDataUploadKeys from '../../../constants/locale/key/ArchivedDataUpload'
import { components, useTranslation } from 'cng-web-lib'

const {
  button: { CngButton }
} = components

function ArchivedDataUploadButtonComponent({
  onSubmit,
}) {

  const { translate } = useTranslation(Namespace.ARCHIVED_DATA_UPLOAD)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let submitButton = translate(
      Namespace.ARCHIVED_DATA_UPLOAD,
      ArchivedDataUploadKeys.SUBMIT_BUTTON
    )
    return {
      submitButton
    }
  }

  return (
    <CngButton onClick={onSubmit}>
      {translatedTextsObject.submitButton}
    </CngButton>
  );
}

export default ArchivedDataUploadButtonComponent;
