//import { CrudTable, DateRangeFilter } from "tfp/components";
import { Card, CardContent, Grid } from '@material-ui/core'

//import CrudCustomMaterialTable from "tfp/components/tables/materialtable/ResponseCrudCustomMaterialTable.js";
import SharedApiUrls from "src/apiUrls/ArrivalResponseApiUrls.js";
import React from "react";
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'
import SharedKeys from 'src/constants/locale/key/Arrivalmessage';
import Namespace from 'src/constants/locale/Namespace';
import moment from 'moment'
import ErrorResponse from 'src/views/aciair/aciaircommon/ErrorResponse';
import { useLocation } from 'react-router-dom'
import { StatusStyle } from 'src/common/NACommon';

const {
  card: {  CngSimpleCardHeader },
  table: {
     CngCrudTable,
     DateRangeFilter,
     useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {EQUAL}
} = constants

function renderErrorMessage(row){
  return (
    <span>
      {row.errorResponses.map(responseErrorDTOs => (<div><ErrorResponse errorCode={responseErrorDTOs.errorCode} rejectType={responseErrorDTOs.rejectType} errorDesc={responseErrorDTOs.errorDesc} responseText={responseErrorDTOs.responseText} showHeader={false}></ErrorResponse><br></br></div>))}
    </span>
  )
}
function ArrivalResponse({
  showNotification,
  arrivalId,
  isViewOnly = false
}){

  const { translate } = useTranslation([Namespace.ARRIVALMESSAGE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const location = useLocation();
  const doctype=location.pathname.toString().indexOf("air")!=-1 ? "AIR" : "OCEAN"


  function makeTranslatedTextsObject() {
    let shared = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.TITLE
    )
  
    let carriercode = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.CARRIERCODE
    )
    let conveyancereferencenumber = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.CONVEYANCEREFERENCENUMBER
    )
    let arrivaldatetime = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.ARRIVALDATETIME
    )
    let portofarrival = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.PORTOFARRIVAL
    )
    let creationdatetime = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.CREATIONDATETIME
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.AUTOSEND
    )
    let status = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.STATUS
    )

     let errorCode = translate(
      Namespace.ARRIVALMESSAGE,
      SharedKeys.ERROR_CODE
      )
    return {
      shared,
      carriercode,
      conveyancereferencenumber,
      arrivaldatetime,
      portofarrival,
      creationdatetime,
      scheduledSubmissionFlag,
      status,
      errorCode
    }
  }

  const columns = [
    {
      field: "convRefNo",
      title: translatedTextsObject.conveyancereferencenumber
    },
    {
      field: "cbsaProcessingDate",
      title: "CBSA ProcessingDate (in EST/EDT)",
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.cbsaProcessingDate).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss')
      }
    },
    {
      field: "receivedDate",
      title: "Received Date (in EST/EDT)",
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.receivedDate).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss')
      }
    },
    {
      field: "status", 
      title: "Status",
      filterPlaceholder: ' ',
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.STATUS,
          undefined,
          undefined,
          undefined,
          'id'
        )
      },
      cellStyle: (e, rowData) => {
        return StatusStyle(rowData.status);
      }
    },
    
    {
        field: "errorCode",
        title:" Error Code | Reject Type | Error Description | Response Text",
        filtering: false,
        render: row => {
          return renderErrorMessage(row);
        },
        cellStyle: () => {
          return {width:'40%'}
      }
    },
  ]; 

  return (
  <Card>
      <CngSimpleCardHeader title="Arrival Response" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              //{...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              exportData={{ url: SharedApiUrls.EXPORT }}
              fetch={{ url: SharedApiUrls.GET}}
              idAccessor={arrivalId}
              notification={showNotification}
              fetchFilters={[{ field: 'headerId', operator: 'equal', value: arrivalId }]}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
              }}
            />
          </Grid>
        </Grid>
    </CardContent>
    </Card>
  )
}
export default ArrivalResponse;
